<template>
  <b-tabs>
    <b-tab title="线下规则">
      <price-fixed-rule-offline-edit/>

      <price-verify-rule-offline-edit/>
    </b-tab>

    <b-tab title="线上规则">
      <price-fixed-rule-online-normal-edit/>

      <price-fixed-rule-online-category-edit/>

      <price-fixed-rule-online-special-edit/>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import priceguideofflineStore from '../priceguideoffline/priceguideofflineStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import PriceGuideOfflineEdit from "@/views/apps/priceguideoffline/PriceGuideOfflineEdit";
import PriceGuideOnlineEdit from "@/views/apps/priceguideonline/PriceGuideOnlineEdit";
import PriceFixedRuleOfflineEdit from "@/views/apps/pricefixedruleoffline/PriceFixedRuleOfflineEdit";
import PriceFixedRuleOnlineNormalEdit from "@/views/apps/pricefixedruleonlinenormal/PriceFixedRuleOnlineNormalEdit";
import PriceVerifyRuleOfflineEdit from "@/views/apps/priceverifyruleoffline/PriceVerifyRuleOfflineEdit";
import PriceFixedRuleOnlineCategoryEdit
  from "@/views/apps/pricefixedruleonlinecategory/PriceFixedRuleOnlineCategoryEdit";
import PriceFixedRuleOnlineSpecialEdit from "@/views/apps/pricefixedruleonlinespecial/PriceFixedRuleOnlineSpecialEdit";

export default {
  components: {
    PriceFixedRuleOnlineSpecialEdit,
    PriceFixedRuleOnlineCategoryEdit,
    PriceVerifyRuleOfflineEdit,
    PriceFixedRuleOnlineNormalEdit,
    PriceFixedRuleOfflineEdit,
    PriceGuideOnlineEdit,
    PriceGuideOfflineEdit,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    BTabs,
    BTab,
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('priceguideoffline')) store.registerModule('priceguideoffline', priceguideofflineStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('priceguideoffline')) store.unregisterModule('priceguideoffline')
    })

    return {
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
